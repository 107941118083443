<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ `${warehouse.company_name}` }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.TAGS") }}
          </dt>
          <dd class="col-sm-8">
            <tags :tags="warehouse.tags" @tagsUpdated="warehouseUpdated" />
          </dd>
        </dl>

        <dl class="row" v-if="warehouse.warehouseable.type">
          <dt class="col-sm-4">
            {{ warehouseableTypeName }}
          </dt>
          <dd class="col-sm-8">
            <router-link
              v-if="warehouse.organization"
              :to="$objectViewRoute(warehouse.warehouseable)"
            >
              {{ `${warehouseableName}` }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TITLE") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.title }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.firstname }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.lastname }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.company_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.PHONE") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.phone }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.country }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.state }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.city }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.zipcode }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="warehouse.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="warehouse.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ warehouse.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Tags from "@/components/Tags.vue";

export default {
  name: "warehouse-view-global",

  props: ["warehouse"],

  components: { Tags },

  data() {
    return {};
  },

  computed: {
    warehouseableName() {
      if (this.warehouse.warehouseable.type == "suppliers") {
        return this.warehouse.warehouseable.company_name;
      } else if (this.warehouse.warehouseable.type == "customers") {
        return this.warehouse.warehouseable.customer_name;
      }
      return "N/A";
    },
    warehouseableTypeName() {
      if (this.warehouse.warehouseable.type == "suppliers") {
        return this.$t("COMMON.SUPPLIER");
      } else if (this.warehouse.warehouseable.type == "customers") {
        return this.$t("COMMON.CUSTOMER");
      }
      return "N/A";
    },
  },

  created() {},

  methods: {
    warehouseUpdated() {
      this.$emit("warehouseUpdated", true);
    },
  },

  mounted() {},

  watch: {
    warehouse(warehouse) {},
  },
};
</script>
