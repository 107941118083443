export default {
  type: "warehouses",
  company_name: null,
  firstname: null,
  lastname: null,
  email: null,
  phone: null,
  country: "Canada",
  state: "Quebec",
  city: null,
  zipcode: null,
  address: null,
  title: null,
  birthday: null,
  excerpt: '',
  relationshipNames: ["organization", "warehouseable", "tags", "allowedLocations"],
  organization: {
    type: "organizations",
    id: null,
  },
  warehouseable: {
    type: null,
    id: null,
  },
  tags: [],
  allowedLocations: [],
};
